<template>
<div>
  <custom-breadcrumb
        :pageTitle="$t('Account Settings')"
        :breadcrumb="breadcrumb"
      ></custom-breadcrumb>
  <b-tabs v-model="tabIndex"
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab :active="option != 'password'" >
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{$t('settings.general')}}</span>
      </template>

<!--   v-if="options.subscriptions"
        @refetchData="getData" -->

      <account-setting-general
        v-if="options.general"
        :general-data="options.general"
        :role="role"
        :lang_options="lang_options"
        :pass="options.pass"
        :subscriptions="options.subscriptions"
        @change-tab="(tab) => tabIndex = tab"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab
      v-if="$can('update', 'Password')"
      :active="option == 'password'"
    >
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{$t('settings.change_pass')}}</span>
      </template>

      <account-setting-password 
        v-if="options.general"
        :email="options.general.email"
      />
    </b-tab>
    <!--/ change password tab -->

    <!-- devices -->
    <b-tab v-if="$can('read', 'Devices')">
      <template #title>
        <feather-icon
          icon="TabletIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{$t('settings.my_devices')}}</span>
      </template>

      <account-setting-devices
        v-if="options.devices"
        :devices="options.devices"
        @refetchData="getData"
      />
    </b-tab>


    <!-- subscriptions -->
    <b-tab v-if="$can('read', 'Subscriptions')">
      <template #title>
        <feather-icon
          icon="CoffeeIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{$t('settings.my_subscriptions')}}</span>
      </template>

      <account-setting-subscriptions
        v-if="options.subscriptions"
        :subscriptions="options.subscriptions"
        @refetchData="getData"
      />
    </b-tab>


  </b-tabs>
  </div>
</template>

<script>
import { getUser } from '@/api/routes'
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
import AccountSettingDevices from './AccountSettingDevices.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'
import AccountSettingSubscriptions from './AccountSettingSubscriptions.vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingDevices,
    AccountSettingInformation,
    AccountSettingSubscriptions,
    AccountSettingNotification,
    CustomBreadcrumb
  },
  data() {
    return {
      options: {},
      lang_options: [],
      breadcrumb:[],
      option: null,
      tabIndex: 1,
      role:''
    }
  },
  created() {
    this.getData()
    //this.$http.get('/account-setting/data').then(res => { // console.log("DEMO DATA", res.data) })
  },
  mounted() {
    this.option = this.$route.query.option
  },
  computed:{
    lang(){
      return this.$store.getters['user/getLang']
    }
  },
  watch:{
    lang(newLang, oldLang){
      this.getData()
    }
  },
  methods:{
    async getData(){
      await this.$http.post(getUser, {}).then( response => {
        // console.log("data:", response.data)
        this.options = response.data.data
        this.lang_options = response.data.lang_options
        this.role = response.data.role
        this.lang_options.map(l => {
          if(l.iso_code == this.options.general.lang){
            this.options.general.lang = l
          }
        })
        
        
        

      }).catch(error => {
          // console.log("Err -> ", error);
          //this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    }
  }
}
</script>
