<template>
  <b-card-actions no-body
    ref="refreshCard"
    :title="$t('settings.my_subscriptions')"
    action-refresh
    @refresh="refreshStop('refreshCard')">
      <b-row>
        <!-- bio -->
        <b-col cols="12">
          <b-table
            striped
            responsive
            :items="subscriptions"
            class="mb-0"
            sticky-header="true"
            :no-border-collapse="true"
            :fields="tableColumns"
            show-empty
            :empty-text="$t('table.empty')"
          >
            <template #cell(details)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                @change="row.toggleDetails"
              >
                {{ row.detailsShowing ? $t('table.hide') : $t('table.show') }}
              </b-form-checkbox>
            </template>

            <!-- full detail on click -->
            <template #row-details="row">
              <b-card>
                <b-row class="mb-1">
                  <b-col
                    md="4"
                    class="mb-1"
                  >
                    <strong>ID: </strong>#{{ row.item.id }}
                  </b-col>
                  <b-col
                    md="4"
                    class="mb-1"
                  >
                    <strong>{{$t('models.payment')}}: </strong>ID: #{{ row.item.payment_id }}
                  </b-col>
                  <b-col
                    md="4"
                    class="mb-1"
                  >
                    <strong>{{$t('fields.created_at')}}: </strong>{{ new Date(row.item.created_at).toLocaleDateString() }}
                  </b-col>
                  <b-col
                    md="4"
                    class="mb-1"
                  >
                    <strong>{{$t('fields.updated_at')}}: </strong>{{ new Date(row.item.updated_at).toLocaleDateString() }}
                  </b-col>
                  <b-col
                    md="4"
                    class="mb-1"
                  >
                    <strong>{{$t('fields.deleted_at')}} : </strong>{{ row.item.deleted_at ? new Date(row.item.deleted_at).toLocaleDateString() : '' }}
                  </b-col>
                </b-row>
                <b-row class="mb-0" v-if="row.item.options">
                  <b-col>
                  <template v-if="!row.item.deleted_at">
                    <template v-if="itemIsActive(row.item)">
                      <p v-if="row.item.payment_method_id == 'GooPla' || row.item.payment_method_id == 'AppSto'"
                        v-html="$t('settings.delete_account_subscr_GooPla_AppSto', {url:row.item.options.url})">
                      </p>
                      <p v-else-if="row.item.payment_method_id == 'Stripe'"
                        v-html="$t('settings.delete_account_subscr_Stripe', {url:row.item.options.url_stripe})">
                      </p>
                      <p v-else v-html="$t('settings.delete_account_contact', {url:row.item.options.email})">
                      </p>
                    </template>
                  </template>
                    
                  </b-col>
                </b-row>

              </b-card>
            </template>

            <template #cell(payment_method_id)="data">

                {{ data.item.payment_method_id }} 
            </template>

            <template #cell(status)="data">
              <div class="d-flex" style="align-items: center">

                  <b-badge :variant="itemIsActive(data.item)?'light-success':'light-danger'">
                    {{ itemIsActive(data.item) ? $t('fields.active') : $t('fields.inactive') }}
                  </b-badge>
                  
              </div>


              </template>

            <template #cell(valid_from)="data">
                {{ data.item.valid_from ? new Date(data.item.valid_from).toLocaleDateString() :''}} 
              </template>
            <template #cell(valid_until)="data">
                {{ data.item.valid_until ?new Date(data.item.valid_until).toLocaleDateString() :''}}
            </template>

            <template #cell(action)="data">
              <template v-if="data.item.payment_method_id == 'Stripe' && dateIs30Days(data.item.valid_until)">
                <a 
                  :href="resolveUrl(data.item)" 
                  target="_blank">
                  <b-button
                    v-b-tooltip.hover.top="$t('settings.manage_subscription')"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-primary"
                    class="btn-icon rounded-circle ml-1 mb-1"
                    >
                    <feather-icon icon="SettingsIcon" />
                  </b-button>
                </a>
              </template>
              <template v-else-if="itemIsActive(data.item)">
                <a 
                  v-if="data.item.payment_method_id == 'Stripe' ||
                        data.item.payment_method_id == 'GooPla' ||
                        data.item.payment_method_id == 'AppSto'"
                  :href="resolveUrl(data.item)" 
                  target="_blank">
                  <b-button
                    v-b-tooltip.hover.top="$t('settings.manage_subscription')"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-primary"
                    class="btn-icon rounded-circle ml-1 mb-1"
                    >
                    <feather-icon icon="SettingsIcon" />
                  </b-button>
                </a>
                <b-button
                    v-else
                    v-b-tooltip.hover.top="$t('settings.delete_account_contact', {url:data.item.options.email})"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-primary"
                    class="btn-icon rounded-circle ml-1 mb-1"
                    >
                    <feather-icon icon="MailIcon" />
                  </b-button>
              </template>
               
            </template>


          </b-table>

        </b-col>
        <!--/ bio -->
      </b-row>
  </b-card-actions>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

import { deleteUserAccount } from '@/api/routes'
import {
  BAlert, BButton, BRow, BCol, BLink,  BCard, BTable,BBadge, 
  VBTooltip, BFormGroup, BFormInput,BCardText,
  BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardActions,
    BLink,
    BRow,
    BCol,
    BFormGroup, BFormInput,BCardText,
    BCard, BTable,BBadge,  BAlert, BButton, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  props: {
    subscriptions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    tableColumns() {
      return [
        { label: this.$t('table.details'), key: 'details', sortable: false },
        { label: this.$t('fields.payment_method_id'), key: 'payment_method_id', sortable: true },
        { label: this.$t('fields.status'), key: 'status' },
        { label: this.$t('fields.valid_from'), key: 'valid_from', sortable: true },
        { label: this.$t('fields.valid_until'), key: 'valid_until', sortable: true },
        { label: this.$t('settings.manage_subscription'), key: 'action', sortable: false },
      ];
    }
  },
  // data() {
  //   return {
  //     tableColumns:[
  //       { label: this.$t('table.details'), key: 'details', sortable: false },
  //       { label: this.$t('fields.payment_method_id'), key: 'payment_method_id', sortable: true },
  //       { label: this.$t('fields.status'), key: 'status' },
  //       { label: this.$t('fields.valid_from'), key: 'valid_from', sortable: true },
  //       { label: this.$t('fields.valid_until'), key: 'valid_until', sortable: true },
  //       { label: this.$t('settings.manage_subscription'), key: 'action', sortable: false },
  //     ],
  //   }
  // },
  methods: {
    resolveUrl(item){
      switch(item.payment_method_id){
        case 'Stripe':
          return item.options.url_stripe;
          break;
        case 'GooPla':
        case 'AppSto':
          return item.options.url;
          break;
        default:
          return item.options.email;
      }
    },
    refreshStop(cardName) {
      this.$emit('refetchData')
      setTimeout(() => {
        this.$refs[cardName].showLoading = false
      }, 3000)
    },
    dateIs30Days(valid_until){
      /* check if valid_until is between now and 30 days before */
      const date = new Date(valid_until);
      const end = new Date();; 
      const start = new Date(new Date().setDate(end.getDate() - 30));

      if (date > start && date < end) {
        return true
      } else {
        return false
      }
    },
    itemIsActive(item){
      /* 
        true = activo
        false = inactivo
      */
      if(!item.deleted_at){
        if(!item.valid_until && !item.valid_from){
          return true
        } else {
          if(item.valid_until && item.valid_from){
            if(new Date(item.valid_from) <= new Date() && new Date(item.valid_until) >= new Date() ){
              return true
            } else {
              return false
            }
          } else if(item.valid_until){
            if(new Date(item.valid_until) >= new Date() ){
              return true
            } else {
              return false
            }
          } else {
            if(new Date(item.valid_from) <= new Date()){
              return true
            } else {
              return false
            }
          }
        }
      } else {
        return false
      }
    },
  },
}
</script>

<style lang="scss">
</style>
