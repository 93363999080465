<template>
<div>
  <b-card>

    <!-- form -->
    <b-form >
      <b-row>
        <b-col sm="6">
          <b-form-group
            :label="$t('fields.email')"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="optionsLocal.email"
              name="email"
              :placeholder="$t('fields.email')"
              :disabled="!canChangeEmail"
            />
          </b-form-group>
        </b-col>
        <!-- alert -->
        <b-col v-if="!canChangeEmail"
          cols="6"
          class="mt-75"
        >
          <b-alert
            show
            variant="warning"
            class="mb-50"
          >
            <h4 class="alert-heading">
              {{$t('settings.email_alert')}}
            </h4>
            <div class="alert-body">{{$t('settings.contact_support')}}</div>
          </b-alert>
        </b-col>
        <!--/ alert -->
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-form-group
            :label="$t('fields.name')"
            label-for="account-name"
          >
            <b-form-input
              v-model="optionsLocal.fullName"
              name="name"
              :placeholder="$t('fields.name')"
            />
          </b-form-group>
        </b-col>
        
        <b-col sm="6">
          <b-form-group
            :label="$t('fields.lang')"
            label-for="account-lang"
          >
          <v-select
            label="local_name"
            v-model="optionsLocal.lang"
            :options="lang_options"
          />
          </b-form-group>
        </b-col> 
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-checkbox
            v-model="is_password_mandatory"
          >
             {{$t('settings.request_password_for_create_children')}}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="save()"
          >
            {{ $t('forms.save_changes') }}
          </b-button>

        </b-col>
      </b-row>
    </b-form>
  </b-card>

  <!-- Modal Delete Account -->
  <b-modal
      id="delete-account"
      ref="delete-account"
      :title="$t('settings.delete_account')"
      
      :ok-title="$t('settings.delete_account')"
      :cancel-title="$t('forms.cancel')"
      ok-variant="danger"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOKDeleteAccount"

      no-close-on-backdrop
    >
      <b-card-text>
        <h5>{{$t('settings.delete_account_text')}}</h5>
        {{$t('settings.delete_account_description')}} {{$t('settings.delete_account_text_continue')}}
        <p>
          {{$t('settings.delete_account_resolve_problem')}} <b>{{ stablished_confirmation_text}}</b>
        </p>
      </b-card-text>
      <form
        ref="deleteAccountForm"
        @submit.stop.prevent="handleDeleteAccount"
      >
        <b-form-group
          :state="deleteAccountState"
          :label="$t('settings.delete_account_problem_solution')"
          label-for="delete_all"
        >
          <!-- invalid-feedback="Confirmation text is required" -->
          <b-form-input
            id="delete_all"
            v-model="confirmation_text"
            :state="deleteAccountState"
            required
          />

          <span class="mt-1" 
           style="color:#ea5455; font-size: 0.857rem; width:100%;"
           v-if="confirmation_text && validate_confirmation_text"
          >{{$t('settings.delete_account_problem_solution_error')}}</span>
        </b-form-group>
      </form>
  </b-modal>

  <!-- Delete account alert msg -->
  <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        {{$t('settings.delete_account')}}
      </h4>
      <div class="alert-body">
        <ul>
        <li>{{$t('settings.delete_account_description')}}</li>
      
        <template v-if="active_suscriptions.length > 0">
          <li>{{$t('settings.you_have_active_subscriptions')}} {{$t('settings.delete_account_subscription')}} </li>
          <b-button
            variant="flat-secondary"
            @click="$emit('change-tab', 3)"
          >
            <feather-icon
              icon="ArrowRightIcon"
              class="mr-50"
            />
            <span class="align-text-top">{{ $t('settings.my_subscriptions') }}</span>
          </b-button>
         
          <li v-if="active_suscriptions.length > 1"> {{$t('settings.delete_account_contact', {url: "support@smileandlearn.com"})}}</li>
          <div class="text-center" v-else-if="active_suscriptions.length == 1 && (
            active_suscriptions[0].payment_method_id == 'AppSto' ||
            active_suscriptions[0].payment_method_id == 'Amazon' ||
            active_suscriptions[0].payment_method_id == 'GooPla' ||
            active_suscriptions[0].payment_method_id == 'Stripe' ||
            active_suscriptions[0].payment_method_id == 'MSWin' ||  active_suscriptions[0].options.allow_deletion
          )">
            <b-button class="m-2" variant="danger" @click="showModalDeleteAccount">{{ $t('settings.delete_account') }}</b-button>
          </div>
          <li v-else> {{$t('settings.delete_account_contact', {url: active_suscriptions[0].options.email})}}</li>
        </template>
        <template v-else>
          <div class="text-center">
            <b-button class="m-2" variant="danger" @click="showModalDeleteAccount">{{ $t('settings.delete_account') }}</b-button>
          </div>
        </template>
        </ul>
      </div>
    </b-alert>
</div>
</template>

<script>
import { deleteUserAccount, editUser } from '@/api/routes'
import {
  BFormCheckbox,
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'

export default {
  components: {
    BFormCheckbox,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,

    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    pass:{
      type: Boolean,
      required: true,
    },
    generalData: {
      type: Object,
      default: () => {},
    },
    role: {
      type: String,
      required: true
    },
    lang_options: {
      type: Array,
      default: () => [],
    },
    subscriptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
      is_password_mandatory: false,

      confirmation_text:'',
      deleteAccountState:null,

    }
  },
  mounted(){
    this.is_password_mandatory = this.pass
  },
  computed:{
    canChangeEmail(){
      return false
      /* @CARO Confirmar funcionalidad con Baldo o Victor */
      //return this.role == 'user' || this.role == 'admin' 
      
    },
    stablished_confirmation_text(){
      let a = Math.floor(Math.random() * 50)
      let b = Math.floor(Math.random() * 50)
      let c = 150 - a - b
      let problem = `${a} + ${b} + ${c}`
      return problem
    },
    validate_confirmation_text(){
      let valid_text = true
      if(this.confirmation_text == "150"){
          valid_text = false
      }
      this.deleteAccountState = !valid_text
      return valid_text
    },
    active_suscriptions(){
      let actives = []
      this.subscriptions.forEach(element => {
        if(this.itemIsActive(element)){
          actives.push(element)
        }
      });
      return actives
    },
  },
  methods: {
    checkFormValidity() {
      const form_valid = this.$refs.deleteAccountForm.checkValidity()

      let valid_text = false
      if(form_valid){
        if(this.confirmation_text =='150'){
          valid_text = true
        }
      }
      this.deleteAccountState = valid_text
      return valid_text
    },
    handleOKDeleteAccount(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleDeleteAccount()
    },
    handleDeleteAccount() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      this.$http.delete(deleteUserAccount).then( response =>{
        if(response.data.status == 200){
          this.makeToast('success', this.$t('Success'), this.$t('settings.delete_account_text_success'));
        } else {
          this.makeToast('danger',this.$t('Error'), this.$t('error_retry_later'));
        }
      }).catch(error => {
        // console.log("Err -> ", error)
          this.makeToast('danger',this.$t('Error'), this.$t('error_retry_later'));
      })

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['delete-account'].toggle('#toggle-btn')
      })
    },
    resetModal() {
      this.confirmation_text = ''
      this.deleteAccountState = null
    },
    showModalDeleteAccount(){
      this.$bvModal.show('delete-account')
    },
    itemIsActive(item){
      /* 
        true = activo
        false = inactivo
      */
      if(!item.deleted_at){
        if(!item.valid_until && !item.valid_from){
          return true
        } else {
          if(item.valid_until && item.valid_from){
            if(new Date(item.valid_from) <= new Date() && new Date(item.valid_until) >= new Date() ){
              return true
            } else {
              return false
            }
          } else if(item.valid_until){
            if(new Date(item.valid_until) >= new Date() ){
              return true
            } else {
              return false
            }
          } else {
            if(new Date(item.valid_from) <= new Date()){
              return true
            } else {
              return false
            }
          }
        }
      } else {
        return false
      }
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    validate(){
      if(this.optionsLocal.email == ''){
          this.makeToast('danger', this.$t('Error'), 'Complete email');
        return false
      }

      if(this.optionsLocal.fullName == ''){
          this.makeToast('danger', this.$t('Error'), 'Complete Name');
        return false
      }
      
      if(this.optionsLocal.lang == ''){
          this.makeToast('danger', this.$t('Error'), 'Select a Language');
        return false
      }
      return true
    },
    async save(){
      if(this.validate()){
        let data = {
          context: 'general',
          name: this.optionsLocal.fullName,
          lang: this.optionsLocal.lang.id,
          child_pass: this.is_password_mandatory,
        }
        if(this.canChangeEmail){
          data['email'] = this.optionsLocal.email
        }
        await this.$http.post(editUser, data).then( response => {
            if(response.data.status == 200){
              this.$i18n.locale = response.data.lang_iso_code
              this.$store.commit('user/changeName', data.name)
              this.$store.commit('user/changeLang', response.data.lang_iso_code)
              this.$store.commit('user/changeURLSSO', response.data.url_sso)

              this.makeToast('success', this.$t('Success'), this.$t('forms.update_success'));
            } else {
              this.makeToast('danger', this.$t('Error'), this.$t(response.data.msg_code));
            }
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        }) 
      }
    }
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
 
}
</script>
